<template>
	<van-popup v-model:show="showimgeditor" :closeable="false" :overlay="true" position="bottom"
		:style="{height:'50%'}" :overlay-style="{opacity:0.2}">
		<div style="position: absolute;left:0px;top:0px;z-index: 10000;width:100%;">
			<div style="padding:8px;font-size:24px;text-align: right;">
				<van-icon name="success" @click="onExecCrop"></van-icon>
			</div>
		</div>
		<vue-picture-cropper :boxStyle="{
			width: '100%',
			height: '100%',
			backgroundColor: '#f8f8f8',
			margin: 'auto'
		}" :img="editingimg" :options="{
			viewMode: 1,
			dragMode: 'crop',
			aspectRatio: 1,
			crop: onCropperDone
		}" @ready="onCropperReady" />
	</van-popup>
	<div class="registerpanel" style="padding-bottom: 64px;">
		<van-nav-bar title="注册" left-text="返回" left-arrow @click-left="onClickLeft" />
		<div style="margin-top:12px;">
			<van-form @submit="onSubmit">
				<van-cell-group inset>
					<van-field name="headimage" input-align="center">
						<template #input>
							<van-uploader :after-read="afterRead">
								<van-image round width="60px" height="60px" :src="imageurl" />
							</van-uploader>
						</template>
					</van-field>
					<van-field v-model="username" label="手机" label-align="right" name="username" placeholder="登录手机号"
						:required="true" />
					<van-field v-model="password" label="密码" label-align="right" type="password" name="password"
						placeholder="请输入密码" :required="true" />
					<van-field v-model="cfmpass" type="password" label="确认密码" label-align="right" name="cfmpass"
						placeholder="确认密码" :required="true" />
					<van-field name="gender" label-align="right" label="性别">
						<template #input>
							<van-radio-group direction="horizontal" v-model="gender">
								<van-radio name="1">先生</van-radio>
								<van-radio name="2">女士</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<van-field v-model="lastname" label="姓氏" label-align="right" name="lastname" placeholder="您的姓氏"
						:required="true" />
					<van-field v-model="firstname" label="名字" label-align="right" name="firstname" placeholder="您的名字"
						:required="true" />
					<van-field v-model="company" label="单位" label-align="right" name="company"
						placeholder="请输入您所在单位名称" />
					<van-field v-model="job" label="职位" label-align="right" name="job" placeholder="请输入您的职位" />
					<van-field v-model="email" label="邮件" label-align="right" name="email" placeholder="请输入您的职位" />
					<van-field v-model="fulladdress" label="地址" label-align="right" name="fulladdress"
						placeholder="请输入您的职位" />
					<div style="margin: 16px;">
						<van-button round block type="primary" native-type="submit">
							提交
						</van-button>
					</div>
				</van-cell-group>
			</van-form>
		</div>
	</div>
</template>

<script>
	import {
		setCookie, doResizeImage
	} from '../util/index.js'
	import {
		Notify
	} from 'vant'
	import VuePictureCropper, {
		cropper
	} from 'vue-picture-cropper'


	export default {
		name: 'Register',
		emits: ['onLoadingData'],
		data() {
			return {
				username: '',
				password: '',
				cfmpass: '',
				lastname: '',
				firstname: '',
				company: '',
				job: '',
				gender: '',
				email: '',
				fulladdress: '',
				imageurl: '',
				headimage: '',
				editingimg: '',
				showimgeditor: false,
			}
		},
		components: {
			//BottomNav,
			VuePictureCropper,
		},
		methods: {
			onCropperReady() {
				console.log(cropper.getDataURL());
			},
			onCropperDone() {
				//console.log(cropper.getDataURL());
			},
			onExecCrop() {
				this.imageurl = cropper.getDataURL();
				this.showimgeditor=false;
				this.himgupdated = true;
			},
			onSubmit(values) {
				values['himage'] = this.imageurl;
				
				console.log('start submit');
								
				this.$emit("onLoadingData", true);
				if(values['himage']) {
					let that = this;
					doResizeImage(values['himage']).then(function (val) {
						values['himage'] = val;
						//console.log(values);
						//console.log('submit then end');
						that.axios.post('api/?entry=user&op=register', values).then(function(res) {
							console.log(res);
							that.loadingdata = false;
							that.$emit("onLoadingData", false);
							if (res.data.rescode == 1) {
								setCookie('vcuseruid', res.data.UserUID, 30);
								setCookie('vcsessiontoken', res.data.SessionToken, 30);
								that.$router.push('/vcard/' + res.data.UserUID);
							} else {
								Notify({
									type: 'danger',
									message: res.data['retinfo']
								});
							}
						});
					});
				}
				else {
					//console.log(values);
					//console.log('submit end');

					let that = this;
					that.axios.post('api/?entry=user&op=register', values).then(function(res) {
						console.log(res);
						that.loadingdata = false;
						that.$emit("onLoadingData", false);
						if (res.data.rescode == 1) {
							setCookie('vcuseruid', res.data.UserUID);
							setCookie('vcsessiontoken', res.data.SessionToken);
							that.$router.push('/vcard/' + res.data.UserUID)
						} else {
							Notify({
								type: 'danger',
								message: res.data['retinfo']
							});
						}
					});
				}
			},
			onClickLeft() {
				this.$router.push("/login")
			},
			afterRead(res) {
				//console.log(res);
				//this.imageurl = res.content;
				this.editingimg = res.content;
				this.showimgeditor=true;
			}
		}
	}
</script>

<style>
</style>
